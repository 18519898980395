body {
  background-color: #666;
  color: #fff;
}

.mainCanvas {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);

  display: flex;
  flex-direction: column;
  gap: 5px;

  max-width: 10rem;
}

.startButton {
  /* position: absolute; */
  /* top: 90%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  background-color: #000;
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.startButton:hover {
  background-color: #fff;
  color: #000;
}

label {
  padding-top: 10px;
}

input[type='text'] {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}